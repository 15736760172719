import React, { Component } from 'react'
import PiBox from '../../components/PiBox'

export default class Features extends Component {
  render() {
    return (
      <div className="Home-Features">
        <div className="content">
          <h1 className="hero">
            We bundle everything that's needed to host apps and websites at home.
          </h1>
          <div className="image">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <Image
                  src={piboxPreview}
                  alt="PiBox, a plug-and-play mini Kubernetes Cluster"
                  layout="responsive"
                /> */}
              <PiBox noLarge={true} />
            </div>
            <div className="caption" style={{ marginBottom: '20px' }}>
              <strong>
                <a
                  rel="noreferrer nofollow"
                  target="_blank"
                  href="https://pibox.io"
                  style={{ color: '#fff' }}
                >
                  PiBox
                </a>
              </strong>
              , a plug-and-play self-hosting device
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2>Order now at </h2>
              <a
                href="https://pibox.io"
                target="_blank"
                rel="noreferrer nofollow"
                className="title"
              >
                Pibox.io
              </a>
            </div>
          </div>
          {/* <div className="features">
            <div>
              <div className="feature">
                <h4>The Self-Hosting Computer</h4>
                <div>
                  We designed the perfect tiny server for self-hosting, based on the Raspberry Pi
                  CM4. We purpose built the PiBox to be the easiest-to-use self-hosting machine in
                  the world!
                </div>
              </div>
              <div className="feature">
                <h4>Remote access</h4>
                <div>
                  Tunnel internet traffic securely to your home without fiddling with your router.
                  Free DDNS, SSH console, firewalls, and everything else you need to host anything
                  from home.{' '}
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="KubeSail Tunneling Docs"
                    href="https://docs.kubesail.com/tunneling/"
                  >
                    Docs ⟶
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="feature">
                <h4>Backups</h4>
                <div>
                  Backup and restore your app's data with our encrypted backup service. Backups are
                  encrypted on your device with your keys before uploading. The privacy of
                  home-hosting with the reliability of the cloud!
                </div>
              </div>
              <div className="feature">
                <h4>Complete self-hosting feature-set</h4>
                <div>
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="KubeSail Template Store"
                    href="https://kubesail.com/templates/"
                  >
                    Apps
                  </a>{' '}
                  let you install and share self-hosted programs in seconds.{' '}
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="KubeSail Builder docs"
                    href="https://docs.kubesail.com/builder/"
                  >
                    Builder
                  </a>{' '}
                  allows you to easily deploy from GitHub.{' '}
                  <a
                    rel="noreferrer nofollow"
                    target="_blank"
                    title="KubeSail Platform docs"
                    href="https://docs.kubesail.com/platform/"
                  >
                    Platform
                  </a>{' '}
                  helps self-hosted software creators sell software to their users.
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={`angle-color ${this.props.currentStep === 2 ? 'black' : 'blue'}`}>
          <div></div>
        </div>
      </div>
    )
  }
}
